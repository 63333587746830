<template>
  <div>
    <!-- <base-header type="gradient-success" class="pb-6 pt-5 pt-md-8"> -->
    <base-header class="pb-6 pt-5 pt-md-8" :style="sitePageTheme.header">
      <!-- Card stats -->
      <div class="row" v-if="clpSitePageSettings.display_banner_image">
        <div class="col text-center">
          <img :src="bannerImg" />
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--5" :style="sitePageTheme.body">
      <div class="row mb-5" v-if="clpSitePageSettings.allow_points_viewing">
        <div class="col-12">
          <a-spin :spinning="totalPointsLoading">
            <div class="spin-content">
              <stats-card
                title="Points"
                :sub-title="totalPoints"
                type="gradient-info"
                icon="fa fa-tags"
                class="mb-4 mb-xl-0"
              >
              </stats-card></div
          ></a-spin>
        </div>
      </div>

      <div class="row">
        <div class="col-8">
          <div class="row" v-if="clpSitePageSettings.allow_points_viewing">
            <div class="col-6">
              <h5 class="card-title text-uppercase text-muted mb-0">
                Points History
              </h5>
            </div>
            <div class="col-6 text-right">
              <router-link
                :to="{
                  name: 'PointsHistory',
                  params: { siteName: clpSitePageSettings.siteName }
                }"
                v-if="clpSitePageSettings.allow_trx_history_view"
              >
                View Details</router-link
              >
            </div>
          </div>
          <div class="row mb-5" v-if="clpSitePageSettings.allow_points_viewing">
            <div class="col-6">
              <a-spin :spinning="earnedThisMonthLoading">
                <div class="spin-content">
                  <stats-card
                    title="Earned This Month"
                    :sub-title="earnedThisMonth"
                    type="gradient-success"
                    icon="fa fa-plus"
                    class="mb-4 mb-xl-0"
                  >
                  </stats-card></div
              ></a-spin>
            </div>
            <div class="col-6">
              <a-spin :spinning="spentThisMonthLoading">
                <div class="spin-content">
                  <stats-card
                    title="Spent This Month"
                    :sub-title="spentThisMonth"
                    type="gradient-danger"
                    icon="fa fa-minus"
                    class="mb-4 mb-xl-0"
                  >
                  </stats-card></div
              ></a-spin>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <h5 class="card-title text-uppercase text-muted mb-0">
                Transaction History
              </h5>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-6">
              <a-spin :spinning="lastPurchaseDateLoading">
                <div class="spin-content">
                  <stats-card
                    title="Last Purchase Date"
                    :sub-title="lastPurchaseDate"
                    type="gradient-info"
                    icon="fa fa-calendar"
                    class="mb-4 mb-xl-0"
                  >
                  </stats-card></div
              ></a-spin>
            </div>
            <div class="col-6">
              <a-spin :spinning="totalPurchaseThisMonthLoading">
                <div class="spin-content">
                  <stats-card
                    title="Total Purchase This Month"
                    :sub-title="totalPurchaseThisMonth"
                    type="gradient-warning"
                    icon="fa fa-usd"
                    class="mb-4 mb-xl-0"
                  >
                  </stats-card></div
              ></a-spin>
            </div>
          </div>
        </div>

        <div class="col-4" v-if="clpSitePageSettings.display_site_text">
          <div class="row">
            <div class="col-12">
              <h5 class="card-title text-uppercase text-muted mb-0">
                Announcements!
              </h5>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card mb-3">
                <div class="row no-gutters">
                  <div class="col-md-12">
                    <div class="card shadow ">
                      <div class="card-header border-0">
                        <p>{{ clpSitePageSettings.site_text }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HTTPRequest from '../controllers/httpRequest';
import moment from 'moment';

export default {
  name: 'Rewards',
  props: {
    clpSitePageSettings: Object,
    memberData: Object,
    sitePageTheme: Object
  },
  data() {
    return {
      totalPoints: 0,
      totalPointsLoading: false,
      earnedThisMonth: 0,
      earnedThisMonthLoading: false,
      spentThisMonth: 0,
      spentThisMonthLoading: false,
      lastPurchaseDate: '1970-01-01',
      lastPurchaseDateLoading: false,
      totalPurchaseThisMonth: 0,
      totalPurchaseThisMonthLoading: false
    };
  },

  methods: {
    getTotalPoints: async function() {
      this.totalPointsLoading = true;

      const response = await HTTPRequest.getRequest(
        `/api/v2/clp_members?q=TOTAL_POINTS&member_id=${this.memberData.id}`
      );

      if (response.total_points != null) {
        this.totalPoints = response.total_points;
      } else {
        this.totalPoints = '0';
      }

      this.totalPointsLoading = false;
    },

    getEarnedThisMonth: async function() {
      this.earnedThisMonthLoading = true;

      const response = await HTTPRequest.getRequest(
        `/api/v2/clp_members?q=MONTHLY_POINTS_EARNED&member_id=${this.memberData.id}`
      );

      if (response.points != null) {
        this.earnedThisMonth = response.points;
      } else {
        this.earnedThisMonth = '0';
      }

      this.earnedThisMonthLoading = false;
    },

    getSpentThisMonth: async function() {
      this.spentThisMonthLoading = true;

      const response = await HTTPRequest.getRequest(
        `/api/v2/clp_members?q=MONTHLY_POINTS_SPENT&member_id=${this.memberData.id}`
      );

      if (response.points != null) {
        this.spentThisMonth = response.points;
      } else {
        this.spentThisMonth = '0';
      }

      this.spentThisMonthLoading = false;
    },

    getLastPurchaseDate: async function() {
      this.lastPurchaseDateLoading = true;

      const response = await HTTPRequest.getRequest(
        `/api/v2/clp_members?q=LAST_PURCHASE_DATE&member_id=${this.memberData.id}`
      );

      if (response.last_transaction_date != null) {
        this.lastPurchaseDate = moment(response.last_transaction_date).format(
          'MM/DD/YYYY'
        );
      } else {
        this.lastPurchaseDate = 'N/A';
      }

      this.lastPurchaseDateLoading = false;
    },

    getTotalPurchaseThisMonth: async function() {
      this.totalPurchaseThisMonthLoading = true;

      const response = await HTTPRequest.getRequest(
        `/api/v2/clp_members?q=MONTHLY_PURCHASED&member_id=${this.memberData.id}`
      );

      if (response.purchase_amount != null) {
        this.totalPurchaseThisMonth = response.purchase_amount;
      } else {
        this.totalPurchaseThisMonth = '0';
      }

      this.totalPurchaseThisMonthLoading = false;
    }
  },

  computed: {
    bannerImg: function() {
      return '/' + this.clpSitePageSettings.banner_image;
    }
  },

  created() {
    this.getTotalPoints();
    this.getEarnedThisMonth();
    this.getSpentThisMonth();
    this.getLastPurchaseDate();
    this.getTotalPurchaseThisMonth();
  }
};
</script>
